// ! ASK FOR PERMISSION BEFORE CHANGING THIS FILE ! BY VISHAL
.ant-table {
    overflow: hidden;
    thead {
        > tr {
            > th {
                overflow-y: hidden;
                padding: $table-th-padding;
                background: $table-th-bg;
                font-weight: 600;
                color: $primary-dark;
                transition: background-color 0.3s ease;
                &::before {
                    display: none;
                }
                .ant-table-selection .ant-checkbox-inner {
                    border: 1px solid $primary-light;
                }
                &:hover {
                    background: darken($table-th-bg, 3%);
                }
            }
        }
    }
}

.std-listing {
    .ant-table {
        // max-height: 57dvh !important;
    }
}

// Responsive table adjustments
@media (max-width: 768px) {
    .ant-table {
        .ant-table-tbody {
            > tr {
                > td {
                    padding: $table-td-padding * 0.8;
                }
            }
        }
    }
}