nz-layout {
    min-height: 100dvh;
}
nz-header {
    height: auto !important;
    border-bottom: 1px solid $header-border-color;
    position: sticky;
    top: 0;
    z-index: 1000;
    backdrop-filter: blur(8px);
    background: rgba($white, 0.95) !important;
    padding: 0 !important;
    background: $header-bg !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}
nz-content {
    min-height: calc(100dvh - 64px);
    background-color: $content-bg !important;
    position: relative;
    overflow: hidden;
}